<template>
  <div>
    <GroupsTable
      v-if="newTestGroups.length > 0 && (evaluatee_view || evaluator_view)"
      :Test="Test"
      :allows_crud="allows_crud"
      :container_instrument_id="container_instrument_id"
      :evaluatee_view="evaluatee_view"
      :evaluator_view="evaluator_view"
      :user_id="user_id"
    ></GroupsTable>
    <b-card
      no-body
      class="mb-2 ml-0 mr-0"
      v-if="!evaluatee_view && !evaluator_view && !preview_evaluation"
    >
      <b-card-header
        header-tag="header"
        class="p-0 d-flex ml-0 mr-0"
        role="tab"
      >
        <b-button
          block
          v-b-toggle.accordion-groups
          class="btn-collapse ml-0 mr-0 p-1 noprint"
          variant="info"
          @click="isAccordionOpen = !isAccordionOpen"
        >
          GRUPOS
          <b-icon v-if="isAccordionOpen" icon="caret-down-fill"></b-icon>
          <b-icon v-else icon="caret-up-fill"></b-icon>
        </b-button>
      </b-card-header>
      <b-collapse
        id="accordion-groups"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <div class="text-left">
          <b-button
            v-if="allows_crud && Test.allow_group_creation"
            variant="primary"
            class="mt-2 ml-4"
            size="sm"
            @click="
              $bvModal.show(
                `create-groups-modal-${Test.id}-${container_instrument_id}`
              )
            "
          >
            + Agregar Grupo
          </b-button>
          <b-button
            v-if="
              allows_crud &&
              Test.allow_group_creation &&
              availableTest.length > 0
            "
            variant="primary"
            class="mt-2 ml-2"
            size="sm"
            @click="
              $bvModal.show(
                `copy-groups-modal-${Test.id}-${container_instrument_id}`
              )
            "
          >
            <b-icon icon="files"></b-icon>
            Copiar Grupo
          </b-button>
        </div>
        <b-card-body>
          <!-- Tabla de grupos -->
          <GroupsTable
            :Test="Test"
            :allows_crud="allows_crud"
            :container_instrument_id="container_instrument_id"
          ></GroupsTable>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-modal
      :id="`create-groups-modal-${Test.id}-${container_instrument_id}`"
      title="Crear Grupo"
      size="lg"
      hide-footer
    >
      <GroupsForm
        :Test="Test"
        @created="
          $bvModal.hide(
            `create-groups-modal-${Test.id}-${container_instrument_id}`
          )
        "
      ></GroupsForm>
    </b-modal>
    <b-modal
      :id="`copy-groups-modal-${Test.id}-${container_instrument_id}`"
      title="Copiar Grupo"
      size="xl"
      @hide="
        () => {
          selected_test = null;
          selected_groups = [];
        }
      "
      hide-footer
    >
      <!-- Seleccionar Grupos -->
      <b-form-group
        :label="`Seleccionar ${$getVisibleNames(
          'evaluations2.test',
          false,
          'Instrumento'
        )}`"
        label-for="input-name"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <b-form-select
          id="input-copy-group"
          v-model="selected_test"
          :options="availableTest"
          size="sm"
          @input="() => (selected_groups = [])"
          value-field="id"
          text-field="title"
          class="mt-1"
          aria-describedby="input-copy-group-feedback"
        ></b-form-select>
        <b-form-invalid-feedback id="input-copy-group-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <template v-if="selectedTest">
        <GroupsTable
          :Test="selectedTest"
          :allows_crud="false"
          :selected_mode="true"
          :container_instrument_id="container_instrument_id"
          :usersNoAvailable="usersNoAvailable"
          @update_checks="checkStudentGroups"
          :id_test_container="Test.id"
        ></GroupsTable>
      </template>
      <div class="text-right">
        <b-button
          size="sm"
          class="mr-1 mt-2"
          @click="copyGroups"
          :disabled="selected_groups.length == 0"
        >
          Guardar
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { toast } from "../../../../utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "GroupsContainer",
  components: {
    GroupsForm: () => import("./GroupsForm"),
    GroupsTable: () => import("./GroupsTable"),
  },
  props: {
    Test: {
      type: Object,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    preview_evaluation: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    container_instrument_id: {
      type: String,
    },
    user_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      isAccordionOpen: false,
      selected_test: null,
      selected_groups: [],
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      new_tests: names.NEW_TESTS,
      new_test_groups: names.NEW_TEST_GROUPS,
    }),
    newTestGroups() {
      return this.new_test_groups.filter((x) => x.test == this.Test.id);
    },
    availableTest() {
      const test_ids = [
        ...new Set(
          this.new_test_groups
            .filter((x) => x.students.length > 0)
            .map((x) => x.test)
        ),
      ];
      return this.new_tests.filter(
        (x) =>
          test_ids.includes(x.id) &&
          x.evaluation == this.Test.evaluation &&
          x.id != this.Test.id
      );
    },
    usersNoAvailable() {
      return [
        ...new Set(
          this.new_test_groups
            .filter((x) => x.test == this.Test.id)
            .map((x) => x.students)
            .flat()
        ),
      ];
    },
    selectedTest() {
      if (this.selected_test == null) return null;
      else return this.new_tests.find((x) => x.id == this.selected_test);
    },
  },
  methods: {
    checkStudentGroups(student_groups) {
      this.selected_groups = student_groups;
    },
    async copyGroups() {
      for await (let group of this.selected_groups) {
        await this.$store.dispatch(names.POST_NEW_TEST_GROUP, {
          name: group.name,
          test: this.Test.id,
          leader: this.usersNoAvailable.includes(group.leader)
            ? null
            : group.leader,
          students: group.students.filter(
            (x) => !this.usersNoAvailable.includes(x)
          ),
        });
      }
      toast(
        this.selected_groups.length > 1 ? "Grupos Copiados" : "Grupo Copiado"
      );
      await this.$bvModal.hide(
        `copy-groups-modal-${this.Test.id}-${this.container_instrument_id}`
      );
    },
  },
  created() {},
};
</script>

<style scoped>
@media print {
  #accordion-groups {
    display: block !important; /* Forza la visibilidad del contenido del acordeón */
  }
}
</style>

