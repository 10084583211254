var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.newTestGroups.length > 0 && (_vm.evaluatee_view || _vm.evaluator_view))?_c('GroupsTable',{attrs:{"Test":_vm.Test,"allows_crud":_vm.allows_crud,"container_instrument_id":_vm.container_instrument_id,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"user_id":_vm.user_id}}):_vm._e(),(!_vm.evaluatee_view && !_vm.evaluator_view && !_vm.preview_evaluation)?_c('b-card',{staticClass:"mb-2 ml-0 mr-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 d-flex ml-0 mr-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-groups",modifiers:{"accordion-groups":true}}],staticClass:"btn-collapse ml-0 mr-0 p-1 noprint",attrs:{"block":"","variant":"info"},on:{"click":function($event){_vm.isAccordionOpen = !_vm.isAccordionOpen}}},[_vm._v(" GRUPOS "),(_vm.isAccordionOpen)?_c('b-icon',{attrs:{"icon":"caret-down-fill"}}):_c('b-icon',{attrs:{"icon":"caret-up-fill"}})],1)],1),_c('b-collapse',{attrs:{"id":"accordion-groups","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"text-left"},[(_vm.allows_crud && _vm.Test.allow_group_creation)?_c('b-button',{staticClass:"mt-2 ml-4",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `create-groups-modal-${_vm.Test.id}-${_vm.container_instrument_id}`
            )}}},[_vm._v(" + Agregar Grupo ")]):_vm._e(),(
            _vm.allows_crud &&
            _vm.Test.allow_group_creation &&
            _vm.availableTest.length > 0
          )?_c('b-button',{staticClass:"mt-2 ml-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `copy-groups-modal-${_vm.Test.id}-${_vm.container_instrument_id}`
            )}}},[_c('b-icon',{attrs:{"icon":"files"}}),_vm._v(" Copiar Grupo ")],1):_vm._e()],1),_c('b-card-body',[_c('GroupsTable',{attrs:{"Test":_vm.Test,"allows_crud":_vm.allows_crud,"container_instrument_id":_vm.container_instrument_id}})],1)],1)],1):_vm._e(),_c('b-modal',{attrs:{"id":`create-groups-modal-${_vm.Test.id}-${_vm.container_instrument_id}`,"title":"Crear Grupo","size":"lg","hide-footer":""}},[_c('GroupsForm',{attrs:{"Test":_vm.Test},on:{"created":function($event){return _vm.$bvModal.hide(
          `create-groups-modal-${_vm.Test.id}-${_vm.container_instrument_id}`
        )}}})],1),_c('b-modal',{attrs:{"id":`copy-groups-modal-${_vm.Test.id}-${_vm.container_instrument_id}`,"title":"Copiar Grupo","size":"xl","hide-footer":""},on:{"hide":() => {
        _vm.selected_test = null;
        _vm.selected_groups = [];
      }}},[_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label":`Seleccionar ${_vm.$getVisibleNames(
        'evaluations2.test',
        false,
        'Instrumento'
      )}`,"label-for":"input-name","label-cols":"0","label-cols-sm":"4","label-cols-md":"4"}},[_c('b-form-select',{staticClass:"mt-1",attrs:{"id":"input-copy-group","options":_vm.availableTest,"size":"sm","value-field":"id","text-field":"title","aria-describedby":"input-copy-group-feedback"},on:{"input":() => (_vm.selected_groups = [])},model:{value:(_vm.selected_test),callback:function ($$v) {_vm.selected_test=$$v},expression:"selected_test"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-copy-group-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),(_vm.selectedTest)?[_c('GroupsTable',{attrs:{"Test":_vm.selectedTest,"allows_crud":false,"selected_mode":true,"container_instrument_id":_vm.container_instrument_id,"usersNoAvailable":_vm.usersNoAvailable,"id_test_container":_vm.Test.id},on:{"update_checks":_vm.checkStudentGroups}})]:_vm._e(),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1 mt-2",attrs:{"size":"sm","disabled":_vm.selected_groups.length == 0},on:{"click":_vm.copyGroups}},[_vm._v(" Guardar ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }